import React from 'react';
// Material UI
import { Snackbar } from "@material-ui/core";
// Material UI Lab
import MuiAlert from "@material-ui/lab/Alert";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SET_SNACKBAR_ALERT } from '../../store/actions/snackbarAlert';

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarAlert = ({ user, show, onClose, ...props }) => {
	const snackbarAlertStore = useSelector((state) => state.snackbarAlertReducer, shallowEqual);
	const dispatch = useDispatch();
	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch({
			type: SET_SNACKBAR_ALERT, payload: {
				...snackbarAlertStore,
				open: false,
			}
		});
	};

	return (
		<Snackbar
			open={snackbarAlertStore.open}
			autoHideDuration={2000}
			onClose={handleCloseSnackbar}
			anchorOrigin={{ vertical: snackbarAlertStore.position.vertical, horizontal: snackbarAlertStore.position.horizontal }}
		>
			<Alert onClose={handleCloseSnackbar} severity={snackbarAlertStore.type}>
				{snackbarAlertStore.message}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarAlert;