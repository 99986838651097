import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
// Context API

const GuestRoute = ({ children, ...rest }) => {
	const auth = useSelector((state) => state.authReducer, shallowEqual);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				!auth.isAuth ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
}

export default GuestRoute;