import { ApiUrl, ImgUrl } from '../../config';
import { SET_AUTH } from '../actions/auth';


const getToken = () => localStorage.getItem("user-auth");

const initialData = {
	isAuth: getToken() ? true : false,
	userFullName: "",
	userLogin: "",
	apiUrl: ApiUrl,
	imgUrl: ImgUrl,
	appTitle: "Street Football (ЛК)",
};


export const authReducer = (state = initialData, action) => {
	switch (action.type) {
		case SET_AUTH:
			return { ...state, isAuth: action.payload };
		default:
			return state;
	}
}