import React from 'react';
import styles from './SuspenseFallback.module.scss';
// Images
import logo from '../../assets/images/logo.svg';

const SuspenseFallback = ({ title = "Заголовок пустой", ...props }) => {

	return (
		<div className={styles.imageContainer}>
			<img alt={logo} src={logo} className={styles.brandLogo} style={{ borderRadius: "50%" }} />
		</div>
	);
}

export default SuspenseFallback;