import React, { useContext, useState, Fragment } from 'react';
// Context API
// React router
import { Link, useHistory, useLocation } from 'react-router-dom';
// Material UI
import {
	makeStyles, Drawer, Collapse, List, Divider, AppBar, ListItem, ListItemIcon, ListItemText, Toolbar, IconButton, Typography,
	Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
// Icons
import {
	Home, Menu as MenuIcon, PhoneIphone, AccountCircle, ExitToApp, History,
	ExpandLess, ExpandMore, SettingsApplications, VerifiedUser, Error, Category, Group,
	Apps, Announcement, LiveHelp, MonetizationOn, Storefront, Assessment, Receipt, Bookmarks, PieChart, List as ListIcon, Accessibility
} from "@material-ui/icons";
// Images
import logo from '../../assets/images/logo.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setAuth, SET_AUTH } from '../../store/actions/auth';

const useStyles = makeStyles(theme => ({
	logo: {
		width: "70px",
		padding: "10px 0",
		borderRadius: "50%",
		marginRight: "15px"
	},
	list: {
		width: "280px",
	},

	menuButton: {
		marginRight: theme.spacing(2),
	},

	nested: {
		paddingLeft: theme.spacing(4),
	},

	title: {
		flexGrow: 1,
	},
}));

const Navbar = () => {
	const auth = useSelector((state) => state.authReducer, shallowEqual)
	const routerHistory = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const appBarMenuOpen = Boolean(anchorEl);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [appDataCollapseOpen, setAppDataCollapseOpen] = useState(false);
	const [reportsCollapseOpen, setReportsCollapseOpen] = useState(false);
	const [transactionCollapseOpen, setTransactionCollapseOpen] = useState(false);
	const [clientsCollapseOpen, setClientsCollapseOpen] = useState(false);
	const dispatch = useDispatch()

	const logout = () => {
		localStorage.removeItem("user-auth");
		dispatch(setAuth(false));
	}

	const navbarItems = [
		{
			title: "Главная",
			link: "/",
			icon: <Home color="primary" />,
			type: "link",
		},
		{
			title: "Список игр",
			link: "/games",
			icon: <History color="primary" />,
			type: "link",
		},
		{
			title: "Список игроков",
			link: "/players",
			icon: <History color="primary" />,
			type: "link",
		},
		{
			title: "Список команд",
			link: "/clubs",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		{
			title: "Список стадионов",
			link: "/stadiums",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		{
			title: "Список чемпионатов",
			link: "/championships",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		{
			title: "Список сервисов",
			link: "/services",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		{
			title: "Список школ",
			link: "/schools",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		{
			title: "Список админов",
			link: "/admins",
			icon: <PieChart color="primary" />,
			type: "link",
		},
		// {
		//     title: "Контент приложения",
		//     type: "dropdown",
		//     icon: <PhoneIphone color="primary"/>,
		//     openValue: appDataCollapseOpen,
		//     openFunc: setAppDataCollapseOpen,
		//     links: [
		//         {
		//             title: "Список категорий",
		//             link: "/app-categories",
		//             icon: <Category  color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список сервисов",
		//             link: "/app-services",
		//             icon: <Apps color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список новостей",
		//             link: "/app-news",
		//             icon: <Announcement  color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список FAQ",
		//             link: "/app-faq",
		//             icon: <LiveHelp  color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список лимитов",
		//             link: "/app-limits",
		//             icon: <Assessment color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список комиссий",
		//             link: "/app-fee",
		//             icon: <MonetizationOn color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список мерчантов",
		//             link: "/app-merchants",
		//             icon: <Storefront  color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Список кешбэк",
		//             link: "/app-cashback",
		//             icon: <ListIcon  color="primary"/>,
		//             type: "link",
		//         },
		//         {
		//             title: "Сортировка кешбэков",
		//             link: "/cashbacks-sort",
		//             icon: <ListIcon  color="primary"/>,
		//             type: "link",
		//         },
		//     ]
		// },
		{
			type: "divider",
		},
		{
			title: "Список пользователей ЛК",
			link: "/lk-users",
			icon: <VerifiedUser color="primary" />,
			type: "link",
		},
		{
			title: "Мой профиль",
			link: "/profile",
			icon: <AccountCircle color="primary" />,
			type: "link",
		},
	];
	const childIsActive = children => {
		const result = children.filter(child => location.pathname === child.link);
		return result.length > 0;
	};

	const toggleDrawer = (anchor, open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpenDrawer(open);
	};

	const sideList = () => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List component="nav">
				{navbarItems.map((navbarItem, counter) => {
					switch (navbarItem.type) {
						case "link":
							return (
								<ListItem
									button
									component={Link}
									to={navbarItem.link}
									key={counter}
									selected={location.pathname === navbarItem.link}
								>
									<ListItemIcon>{navbarItem.icon}</ListItemIcon>
									<ListItemText primary={navbarItem.title} />
								</ListItem>
							);
							break;
						case "dropdown":
							return (
								<Fragment key={counter}>
									<ListItem
										selected={childIsActive(navbarItem.links)}
										button
										onClick={e => { navbarItem.openFunc(!navbarItem.openValue); e.stopPropagation(); }}
									>
										<ListItemIcon>{navbarItem.icon}</ListItemIcon>
										<ListItemText primary={navbarItem.title} />
										{navbarItem.openValue ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={navbarItem.openValue} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{navbarItem.links.map((nestedLink, counter) => (
												<ListItem
													button
													className={classes.nested}
													component={Link}
													to={nestedLink.link}
													selected={location.pathname === nestedLink.link}
													key={counter}
												>
													<ListItemIcon>{nestedLink.icon}</ListItemIcon>
													<ListItemText primary={nestedLink.title} />
												</ListItem>
											))}
										</List>
									</Collapse>
								</Fragment>
							);
							break;
						case "divider":
							return (
								<Divider style={{ margin: "12px 0" }} key={counter} />
							);
							break;
						default:
							return null;
					}
				})}
				<ListItem button onClick={() => setDialogOpen(true)}>
					<ListItemIcon><ExitToApp color="primary" /></ListItemIcon>
					<ListItemText primary="Выйти" />
				</ListItem>
			</List>
		</div>
	);

	if (auth.isAuth) {
		return (
			<>
				<AppBar className={classes.navbar} position="sticky">
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
							<MenuIcon />
						</IconButton>
						<img src={logo} alt="МДО Хумо" className={classes.logo} />
						<Typography variant="h6" className={classes.title}>
							{auth.appTitle}
						</Typography>
						<div>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={event => setAnchorEl(event.currentTarget)}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={appBarMenuOpen}
								onClose={() => setAnchorEl(null)}
							>
								<MenuItem onClick={() => { setAnchorEl(null); routerHistory.push("/profile"); }}>Мой профиль</MenuItem>
								<MenuItem onClick={() => { setAnchorEl(null); setDialogOpen(true); }}>Выйти</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer open={openDrawer} onClose={toggleDrawer(false)}>
					{sideList()}
				</Drawer>
				<Dialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
				>
					<DialogTitle>{"Выход"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Вы действительно хотите выйти?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDialogOpen(false)} color="secondary" variant="contained">Нет</Button>
						<Button onClick={() => { setDialogOpen(false); logout() }} color="secondary" autoFocus variant="contained">Да</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	} else if (location.pathname === "/404") {
		return (
			<>
				<AppBar position="sticky">
					<Toolbar>
						<img src={logo} alt="МДО Хумо" className={classes.logo} />
						<Typography variant="h6" className={classes.title}>
							{auth.appTitle}
						</Typography>
					</Toolbar>
				</AppBar>
			</>
		);
	}
	else {
		return null;
	}
};

export default Navbar;