import { SET_SNACKBAR_ALERT } from '../actions/snackbarAlert';

const initialData = {
	open: false,
	message: "",
	type: "success",
	position: {
		vertical: "top",
		horizontal: "center",
	}
};


export const snackbarAlertReducer = (state = initialData, action) => {
	switch (action.type) {
		case SET_SNACKBAR_ALERT:
			return action.payload;
		default:
			return state;
	}
}