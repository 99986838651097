import { lazy } from 'react';

// Admin panel
export const Home = lazy(() => import('../Home/Home'));
export const NotFound = lazy(() => import('../NotFound/NotFound'));
export const Profile = lazy(() => import('../Profile/Profile'));
export const Login = lazy(() => import('../Login/Login'));
export const PlayersList = lazy(() => import('../Players/Players'));
export const ClubsList = lazy(() => import('../Clubs/Clubs'));
export const GamesList = lazy(() => import("../Games/Games"));
export const StadiumsList = lazy(() => import('../Stadiums/Stadiums'));
export const ChampionshipsList = lazy(() => import('../Championships/ChampionshipsList'));
export const ChampionshipItem = lazy(() => import("../Championships/Item/Item"))
export const AdminsList = lazy(() => import('../Admins/AdminsList'));
export const ServicesList = lazy(() => import('../Services/ServiceList'))
export const SchoolsList = lazy(() => import('../Schools/SchoolsList'))
export const ChampionshipNotifications = lazy(() => import('../Championships/Notifications/Notifications'));
