import React, { useContext } from 'react';
// React router
import { useLocation } from 'react-router-dom';
// Material UI
import { Box, Typography, makeStyles, AppBar, Toolbar } from "@material-ui/core";
// Images
import logo from '../../assets/images/logo.jpg';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	footer: {
		color: "white",
		backgroundColor: theme.palette.primary.main,
		marginTop: "auto",
		padding: "16px 0",
	},

	logo: {
		width: "90px",
		margin: "auto",
	}
}));

const Footer = () => {
	const classes = useStyles();
	const auth = useSelector((state) => state.authReducer, shallowEqual)
	const location = useLocation();

	if (auth.isAuth || location.pathname === "/404") {
		return (
			<footer className={classes.footer}>
				<Box mb={2}>
					<img className={classes.logo} src={logo} style={{ borderRadius: "50%" }} />
				</Box>
				<Box>
					<Typography component="p" align="center">
						{auth.appTitle} {new Date().getFullYear()}
					</Typography>
				</Box>
			</footer>
		);
	} else {
		return null;
	}
};

export default Footer;