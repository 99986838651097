import React, { useContext, Suspense } from 'react';
// Context API
// Lazy components
import { Login, GamesList, ChampionshipItem, Home, NotFound, PlayersList, ClubsList, StadiumsList, ChampionshipsList, AdminsList, ServicesList, SchoolsList, ChampionshipNotifications } from "./components/Lazy/Lazy";
// React router
import { Route, Switch, Redirect } from 'react-router-dom';
// Routes type
import GuestRoute from './components/Routes/GuestRoute';
import PrivateRoute from './components/Routes/PrivateRoute';
// Styles
import './App.scss';
// Navbar
import Navbar from "./components/Navbar/Navbar";
// Footer
import Footer from "./components/Footer/Footer";
// Scroll to top
import ScrollTop from "./components/Utils/ScrollTop";

// Material UI
import { CssBaseline, Fab, makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ruRU } from "@material-ui/core/locale";
// Icons
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// Snackbar alert
import SnackbarAlert from "./components/Utils/SnackbarAlert";
// Suspense fallback
import SuspenseFallback from "./components/SuspenseFallback/SuspenseFallback";
import { shallowEqual, useSelector } from 'react-redux';


const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#542e71",
			contrastText: '#FFFFFF',
		},

		secondary: {
			main: "#fb3640",
			contrastText: '#FFFFFF',
		},
	},
}, ruRU);

const useStyles = makeStyles(theme => ({
	app: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
	},

	mainContainer: {
		paddingBottom: "50px",
	}
}));

const App = props => {
	const auth = useSelector((state) => state.authReducer, shallowEqual);
	const classes = useStyles();

	return (
		<div className={classes.app}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Navbar />
				<Suspense fallback={<SuspenseFallback />}>
					<main className={auth.isAuth ? classes.mainContainer : ""}>
						<Switch>
							<GuestRoute path="/login" exact>
								<Login />
							</GuestRoute>
							<PrivateRoute path="/" exact>
								<Home />
							</PrivateRoute>
							<PrivateRoute path="/games" exact>
								<GamesList title={"Список игр"} />
							</PrivateRoute>
							<PrivateRoute path="/players" exact>
								<PlayersList title={"Список игроков"} />
							</PrivateRoute>
							<PrivateRoute path="/clubs" exact>
								<ClubsList title={"Список команд"} />
							</PrivateRoute>
							<PrivateRoute path="/stadiums" exact>
								<StadiumsList title={"Список стадионов"} />
							</PrivateRoute>
							<PrivateRoute path="/stadiums" exact>
								<StadiumsList title={"Список стадионов"} />
							</PrivateRoute>
							<PrivateRoute path="/championships" exact>
								<ChampionshipsList title={"Список чемпионатов"} />
							</PrivateRoute>
							<PrivateRoute path="/championships/:id" exact>
								<ChampionshipItem title={"Список чемпионатов по ID"} />
							</PrivateRoute>
							<PrivateRoute path="/services" exact>
								<ServicesList title={"Список сервисов"} />
							</PrivateRoute>
							<PrivateRoute path="/schools" exact>
								<SchoolsList title={"Список школ"} />
							</PrivateRoute>
							<PrivateRoute path="/admins" exact>
								<AdminsList title={"Список админов"} />
							</PrivateRoute>
							<PrivateRoute path="/championships/notifications/:id" exact>
								<ChampionshipNotifications title={"Список заявок чемпионата"} />
							</PrivateRoute>
							{/*<PrivateRoute path="/suspense" exact>*/}
							{/*    <SuspenseFallback />*/}
							{/*</PrivateRoute>*/}
							{/* <PrivateRoute path="/lk-users" exact>
                  <DashboardUsersList title="Список пользователей ЛК"/>
                </PrivateRoute> */}
							{/*<Route path="*">*/}
							{/*  <NotFound />*/}
							{/*</Route>*/}
							<Route path="/404">
								<NotFound title="Страница 404" />
							</Route>
							<Redirect to="/404" />
						</Switch>
					</main>
				</Suspense>
				<Footer />
				<ScrollTop {...props}>
					<Fab color="primary" size="large" aria-label="scroll back to top">
						<KeyboardArrowUpIcon style={{ color: "white" }} />
					</Fab>
				</ScrollTop>
				<SnackbarAlert />
			</ThemeProvider>
		</div>
	);
};

export default App;
