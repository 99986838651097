export const SET_AUTH = "SET_AUTH";


export const setAuth = (bool) => {
	return {
		type: SET_AUTH,
		payload: bool,
	}
}

export const getProfileInfo = ({ login, password }) => {
	return async function (dispatch) {
		try {

		}
		catch (e) {

		}
	}
}